import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import React from 'react'
import { colors } from '../theme'
import { DoDisturb } from '@mui/icons-material'

interface AdoptionModalProps {
  open: boolean
  handleClose: () => void
  donation: number
  handleDonationChange: (event: React.MouseEvent<HTMLElement>, newDonation: number | null) => void
}

export const AdoptionDonationEditModal: React.FC<AdoptionModalProps> = ({
  open,
  handleClose,
  donation,
  handleDonationChange,
}) => {
  const StyledToggleButton = styled(ToggleButton)(() => ({
    borderRadius: '8px !important', // Rounded corners
    margin: '0 8px', // Spacing between buttons
    border: 'none', // No border
    textTransform: 'none', // Disable uppercase text
    fontSize: '16px', // Custom font size
    fontWeight: 'bold', // Bold font
    backgroundColor: 'rgba(217, 217, 217, 1)', // Background color
    color: 'rgba(87, 87, 87, 1)', // Text color

    '&:hover': {
      backgroundColor: colors.blueSky, // Background color on hover
    },

    '&:active': {
      backgroundColor: colors.blueSky, // Background color when active
    },

    '&.Mui-selected': {
      backgroundColor: colors.blueSky + ' !important', // Background color when selected
      color: 'white', // Text color when selected
    },
  }))

  const thanksMessage = (donation: number) => {
    switch (donation) {
      case 0:
        return ''
      case 5:
        return 'Vous couvrez au moins les frais de paiements'
      case 10:
        return 'Vous nous permettez de rester dans nos frais'
      case 20:
        return 'Vous nous permettez de développer nos services et de venir en aide à de nouvelles assos !'
      default:
        return 'Vous nous permettez de développer nos services et de venir en aide à de nouvelles assos !'
    }
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="adoption-modal-title"
      sx={{
        '& .MuiDialog-paper': {
          width: '100%', // Ensures it takes up the full width
          maxHeight: '100%', // Prevents overflow
          margin: 0, // Removes any default margin
        },
      }}
    >
      {/* Dialog Content */}
      <DialogContent
        sx={{
          padding: '8px', // Reduced padding
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexGrow: 1, // Ensures the content stretches
        }}
      >
        {/* Header Box */}
        <Box
          sx={{
            backgroundColor: colors.blueSky,
            color: 'white',
            fontSize: '20px',
            fontWeight: 700,
            width: 'fit-content',
            p: 1,
            py: 0.5,
            pr: 2,
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
            marginBottom: '24px',
          }}
        >
          💡 Modifier ma contribution
        </Box>

        {/* Text Content */}
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'start', justifyContent: 'start' }}>
            <ToggleButtonGroup size="large" value={donation} onChange={handleDonationChange} exclusive fullWidth>
              <StyledToggleButton value={0} key="0">
                <DoDisturb />
              </StyledToggleButton>
              <StyledToggleButton value={5} key="5">
                5€
              </StyledToggleButton>
              <StyledToggleButton value={10} key="10">
                10€
              </StyledToggleButton>
              <StyledToggleButton value={20} key="20">
                20€
              </StyledToggleButton>
            </ToggleButtonGroup>
          </Box>
          {donation && donation !== 0 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'start',
                backgroundColor: 'rgba(210, 233, 247, 1)',
                p: 1,
                borderRadius: '7px',
                mt: 3,
                mb: 4,
              }}
            >
              <Typography variant="body1" sx={{ fontSize: '16px' }}>
                <b>Merci !</b> {thanksMessage(donation)}
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>

      {/* Dialog Actions */}
      <DialogActions sx={{ padding: '8px' }}>
        {' '}
        {/* Reduced padding in the actions area */}
        <Button onClick={handleClose} variant="contained" color="primary" fullWidth sx={{ fontSize: '18px' }}>
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  )
}
