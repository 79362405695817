import { Looks3, LooksOne, LooksTwo } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { theme } from '../../theme'

export const Two: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 3, height: '100%' }}>
      {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 3 }}>
        <CheckCircleOutline sx={{ color: 'white', fontSize: '50px' }} />
        <Typography variant="body1" color="white" fontWeight={700} fontSize={15}>
          Vous avez trouvé l’animal de vos rêves et avez contacté l’association qui a validé le sérieux de votre demande
        </Typography>
      </Box> */}
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          fontSize: '20px',
          fontWeight: 700,
          width: 'fit-content',
          p: 1,
          py: 0.5,
          borderRadius: '11px',
          transform: 'rotate(-1deg)',
        }}
      >
        A présent sur Petso
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
          <LooksOne sx={{ color: 'white', fontSize: '34px' }} />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Typography variant="body1" color="white" fontWeight={700} fontSize={15}>
              Signer le certificat d’engagement
            </Typography>
            <Typography variant="body1" color="white" fontStyle="italic" fontSize={13}>
              Obligatoire depuis Octobre 2022
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
          <LooksTwo sx={{ color: 'white', fontSize: '34px' }} />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Typography variant="body1" color="white" fontWeight={700} fontSize={15}>
              Signer le contrat d’adoption
            </Typography>
            <Typography variant="body1" color="white" fontStyle="italic" fontSize={13}>
              C’est la transmission de propriété de l’animal
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
          <Looks3 sx={{ color: 'white', fontSize: '34px' }} />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Typography variant="body1" color="white" fontWeight={700} fontSize={15}>
              Effectuer le réglement des frais d&apos;adoption
            </Typography>
            <Typography variant="body1" color="white" fontStyle="italic" fontSize={13}>
              Facultatif, d’autres moyens sont possible
            </Typography>
          </Box>
        </Box>
      </Box>
      <Typography variant="body1" color="white" fontWeight={700} fontSize={17} sx={{ mt: 2 }}>
        L’adoption n’est pas à prendre à la légère. Elle a des implications à commencer par le respect d’une procédure
        légale à respecter.
      </Typography>
    </Box>
  )
}
