import { metricalpEvent } from '@metricalp/react'
import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { AnimalsClient } from '../../interactors/clients/AnimalsClient'
import { AdoptionAttemptDto, AnimalDto } from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { colors } from '../theme'
import { NewNameFormData, One } from './Steps/One'
import { Two } from './Steps/Two'
import { useMutation } from 'react-query'
import { AdoptersClient } from '../../interactors/clients/AdoptersClient'

export const AdoptedTutorial: React.FC = () => {
  const accountsStore = useAccountsStore()
  const connectedAccount = accountsStore.connectedAccount
  const attempt = connectedAccount?.adoptionAttempts?.find(
    (attempt) => attempt.done && (attempt.preCertificateDialogStep == null || attempt.preCertificateDialogStep < 1)
  )

  const [step, setStep] = React.useState(1)
  const [animal, setAnimal] = React.useState<AnimalDto | null>()
  const [animalName, setAnimalName] = React.useState<string>('')

  useEffect(() => {
    const fetch = async () => {
      if (attempt) {
        const animal = await AnimalsClient.getAnimalById(attempt.animalId)
        setAnimal(animal)
      }
    }

    fetch()
  }, [])

  const updateMutation = useMutation(
    async (data: { adoptionAttempt: AdoptionAttemptDto; id: string }) => {
      return await AdoptersClient.updateAdoptionAttemptDummyInfos(data)
    },
    {
      onSuccess: (attempt) => {
        if (!accountsStore.connectedAccount) return
        const newAdopter = {
          ...accountsStore.connectedAccount,
          adoptionAttempts: accountsStore.connectedAccount?.adoptionAttempts?.map((a) =>
            a.id === attempt.id ? attempt : a
          ),
        }
        accountsStore.changeConnectedAccount(newAdopter)
      },
    }
  )

  const handleFormSubmitName = async (data: NewNameFormData) => {
    if (!attempt) return
    setAnimalName(data.name)
    if (data.name === attempt.newName) {
      handleNextStep()
      return
    }
    const newAttempt: AdoptionAttemptDto = { ...attempt, petHasNewName: true, newName: data.name }
    await updateMutation.mutateAsync({ adoptionAttempt: newAttempt, id: attempt.id })
    handleNextStep()
  }

  const handleNextStep = async () => {
    if (step === 1) {
      metricalpEvent({
        type: `adopted_rename_animal`,
        adopterId: accountsStore.connectedAccount?.id,
        adopterName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
      })
    }
    if (step === 2) {
      metricalpEvent({
        type: `adopted_tutorial_petso`,
        adopterId: accountsStore.connectedAccount?.id,
        adopterName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
        done: true,
      })
    }
    setStep(step + 1)
  }

  if (!animal || !attempt) return null

  const renderStep = () => {
    switch (step) {
      case 1:
        return <One animal={animal} onSubmit={handleFormSubmitName} />
      case 2:
        return <Two key={animalName} animalName={animalName} />
      case 3:
        return (
          <Navigate
            to={`/profil/mon-profil?fromTutorial=true&redirect=/adoptions${
              attempt.id ? `/${attempt.id}` : ''
            }?fromTutorial=true`}
          />
        )
      default:
        return null
    }
  }
  return (
    <Box
      sx={{
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        padding: { xs: 0, md: 0 },
        height: '100%',
        width: '100%',
        pb: 2,
        bgcolor: colors.blueSky,
      }}
    >
      <Box
        sx={{
          maxWidth: '1200px',
          marginX: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          py: 4,
          px: 3,
          gap: 2,
          width: '100%',
          flexGrow: 1,
        }}
      >
        {renderStep()}
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', pb: 2, gap: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: { xs: '100%', md: 'calc(100% - 240px)' },
              justifyContent: 'end',
              mb: { xs: 0, md: 2 },
            }}
          >
            <LoadingButton
              variant="contained"
              type="submit"
              form="newNameForm"
              onClick={() => (step === 1 ? null : handleNextStep())}
              sx={{ fontSize: '18px' }}
            >
              {step < 2 ? `Valider` : `Commencer`}
            </LoadingButton>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', position: 'relative' }}>
            <ul className="slick-dots slick-dots--tutorial" style={{ bottom: '0px !important' }}>
              <li className={step === 1 ? 'slick-active' : ''}>
                <div className="ft-slick__dots--tutorial"></div>
              </li>
              <li className={step === 2 ? 'slick-active' : ''}>
                <div className="ft-slick__dots--tutorial"></div>
              </li>
            </ul>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
