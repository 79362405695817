import React from 'react'
import { One } from './CaatsSteps/One'
import { AnimalDto } from '../../interactors/gen/backendClient'
import { Two } from './CaatsSteps/Two'
import { Three } from './CaatsSteps/Three'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { metricalpEvent } from '@metricalp/react'

interface Props {
  animal: AnimalDto
  animalName: string
  skipEnd?: boolean
  alreadyAdopted?: boolean
  adopterId: string
  adopterName: string
}

export const CaatsSteps: React.FC<Props> = ({
  animal,
  skipEnd,
  animalName,
  alreadyAdopted,
  adopterId,
  adopterName,
}) => {
  const [step, setStep] = React.useState(1)
  const [answered, setAnswered] = React.useState(false)

  const globalSnackbarStore = useGlobalSnackbarStore()

  const navigate = useNavigate()
  const location = useLocation()

  const goNextStep = (answer?: 'yes' | 'no') => {
    if (step === 1) {
      metricalpEvent({
        type: `${alreadyAdopted ? 'adopted' : 'adoption'}_caats`,
        adopterId: adopterId,
        adopterName: adopterName,
        animalName: animal?.name,
        animalId: animal?.id,
        decision: 'accepted',
      })
    }
    if (step === 2) {
      metricalpEvent({
        type: `${alreadyAdopted ? 'adopted' : 'adoption'}_review_caats`,
        adopterId: adopterId,
        adopterName: adopterName,
        animalName: animal?.name,
        animalId: animal?.id,
        survey: answer,
      })
      setAnswered(true)
      if (skipEnd) {
        globalSnackbarStore.triggerSuccessMessage('Merci pour votre participation !')
        return onLeave('home')
      }
    }
    setStep(step + 1)
  }

  const onSkip = () => {
    metricalpEvent({
      type: `${alreadyAdopted ? 'adopted' : 'adoption'}_caats`,
      adopterId: adopterId,
      adopterName: adopterName,
      animalName: animal?.name,
      animalId: animal?.id,
      decision: 'refused',
    })
    setStep(3)
  }

  const onLeave = (to: 'home' | 'profil') => {
    metricalpEvent({
      type: `${alreadyAdopted ? 'adopted' : 'adoption'}_leaving_caats`,
      adopterId: adopterId,
      adopterName: adopterName,
      animalName: animal?.name,
      animalId: animal?.id,
      to: to,
    })
    const param = to === 'home' ? '' : '?page=profil'
    navigate(`${location.pathname}${param}`)
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return <One onSubmit={goNextStep} onSkip={onSkip} />
      case 2:
        return (
          <Two
            onSubmit={goNextStep}
            animal={animal}
            animalName={animalName || ''}
            alreadyAdopted={alreadyAdopted}
            adopterId={adopterId}
            adopterName={adopterName}
          />
        )
      case 3:
        return <Three onSubmit={onLeave} animal={animal} answered={answered} animalName={animalName || ''} />
      default:
        return <One onSubmit={goNextStep} onSkip={onSkip} />
    }
  }
  return renderStep()
}
