import { Box, Typography } from '@mui/material'
import React from 'react'
import { colors, theme } from '../../theme'

interface Props {
  animalName: string
}

export const Two: React.FC<Props> = ({ animalName }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 3, height: '100%' }}>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          fontSize: '20px',
          fontWeight: 700,
          width: 'fit-content',
          p: 1,
          py: 0.5,
          borderRadius: '11px',
          transform: 'rotate(-1deg)',
        }}
      >
        L’espace bien-être de {animalName}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
          <Typography variant="body1" color="white" fontSize={38}>
            📑
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1" color="white" fontWeight={700} fontSize={17}>
              Ses documents
            </Typography>
            <Typography variant="body1" color="white" fontStyle="italic" fontSize={14}>
              liés à l’adoption et à sa santé
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
          <Typography variant="body1" color="white" fontSize={38}>
            🏥
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1" color="white" fontWeight={700} fontSize={17}>
              Ses rappels de santé
            </Typography>
            <Typography variant="body1" color="white" fontStyle="italic" fontSize={14}>
              vaccinations, vermifuges, antiparasitaires
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
          <Typography variant="body1" color="white" fontSize={38}>
            🎁
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1" color="white" fontWeight={700} fontSize={17}>
              De conseils & recommandations
            </Typography>
            <Typography variant="body1" color="white" fontStyle="italic" fontSize={14}>
              Et des cadeaux de bienvenue !
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
          <Typography variant="body1" color="white" fontSize={38}>
            🧑‍⚕️
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1" color="white" fontWeight={700} fontSize={17}>
              L&apos;accès à des experts
            </Typography>
            <Typography variant="body1" color="white" fontStyle="italic" fontSize={14}>
              Réservations, téléconsultations, messagerie...
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
          <Typography variant="body1" color="white" fontSize={38}>
            😻️
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1" color="white" fontWeight={700} fontSize={17}>
              Compléter son profil en continu
            </Typography>
            <Typography variant="body1" color="white" fontStyle="italic" fontSize={14}>
              Et ajouter vos autres animaux !
            </Typography>
          </Box>
        </Box>
        <Box sx={{ position: 'relative', backgroundColor: 'white', mx: -3, transform: 'rotate(-1deg)', py: 1 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
              gap: 3,
              width: '100%',
              px: 3,
              transform: 'rotate(1deg)',
            }}
          >
            <Typography variant="body1" color="white" fontSize={38}>
              🎁
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" color={colors.blueSky} fontWeight={700} fontSize={17}>
                7 jours gratuits !
              </Typography>
              <Typography variant="body1" color={colors.blueSky} fontStyle="italic" fontSize={13}>
                En partenariat avec l’association, bénéficiez de nos offres exclusives et recommandations pour son
                arrivée.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
