import { Avatar, Box, Button, Typography } from '@mui/material'
import React from 'react'
import { AnimalDto } from '../../../interactors/gen/backendClient'
import { blue, colors } from '../../theme'
import {
  getEmojiSpecies,
  getReadableAnimalSterilizedOrNeutered,
  getReadableSex,
} from '../../../domain/Animal/AnimalDisplay'
import { calculateAge } from '../../../domain/Animal/calculateAge'
import dayjs from 'dayjs'
import { getAnimalProfilePhotoSrc } from '../../../utils/S3-links'

interface Props {
  onSubmit: (to: 'home' | 'profil') => void
  animal: AnimalDto
  animalName: string
  answered?: boolean
}

export const Three: React.FC<Props> = ({ onSubmit, animal, answered, animalName }) => {
  const profileImageKey = animal?.images?.profileImageKey || ''
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 'calc(100dvh - 64px)',
        gap: 1,
        p: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          gap: 3,
          width: '100%',
        }}
      >
        {answered && (
          <Typography sx={{ fontWeight: 'bold', color: colors.seaGreen }}>
            Merci votre réponse a bien été prise en compte !
          </Typography>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Avatar
            src={profileImageKey ? getAnimalProfilePhotoSrc(profileImageKey) : ''}
            alt={`Photo de ${animalName}`}
            sx={{ height: '140px', width: '140px', fontSize: '72px' }}
          >
            {getEmojiSpecies(animal.species)}
          </Avatar>
        </Box>
        <Box
          sx={{
            backgroundColor: colors.blueSky,
            color: 'white',
            fontSize: '20px',
            fontWeight: 700,
            width: 'fit-content',
            p: 1,
            py: 0.5,
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
          }}
        >
          Le profil de {animalName}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', gap: 2, width: '100%', mt: 1 }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', color: blue }}>
              <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'inherit', lineHeight: '18px' }}>
                Nom
              </Typography>
              <Typography sx={{ fontSize: 14, color: 'inherit' }}>{animalName}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', color: blue }}>
              <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'inherit', lineHeight: '18px' }}>
                Sexe
              </Typography>
              <Typography sx={{ fontSize: 14, color: 'inherit' }}>
                {animal.sex ? getReadableSex(animal.sex) : '..'}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', color: blue }}>
              <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'inherit', lineHeight: '18px' }}>
                Stérilisé(e)
              </Typography>
              <Typography sx={{ fontSize: 14, color: 'inherit' }}>
                {animal.health.sterilizedOrNeutered
                  ? getReadableAnimalSterilizedOrNeutered(animal.health.sterilizedOrNeutered)
                  : '..'}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', color: blue, width: '100%' }}>
            <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'inherit', lineHeight: '18px' }}>
              Âge - Date de naissance
            </Typography>
            <Typography sx={{ fontSize: 14, color: 'inherit' }}>
              {`${animal.birthday?.date ? calculateAge(animal.birthday.date) : '..'} - ${
                animal.birthday?.date ? dayjs(animal.birthday.date).format('DD/MM/YYYY') : '..'
              }`}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ fontSize: '18px' }}
          onClick={() => onSubmit('profil')}
        >
          Consulter tout le profil
        </Button>
        <Button
          variant="text"
          sx={{
            borderColor: '#c5d2de',
            color: '#6b7990',
            textTransform: 'none',
            my: 1,
            width: '100%',
            fontSize: '18px',
            textDecoration: 'underline',
            ':hover': {
              backgroundColor: '#eef2f7',
            },
          }}
          onClick={() => onSubmit('home')}
        >
          les autres offres de bienvenue
        </Button>
      </Box>
    </Box>
  )
}
