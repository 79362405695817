import { Star, StarHalf } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { blue } from '@mui/material/colors'
import React from 'react'
import { colors, theme } from '../../theme'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

interface Props {
  onSubmit: () => void
  onSkip: () => void
}

export const One: React.FC<Props> = ({ onSubmit, onSkip }) => {
  const settings = {
    dots: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    initialSlide: 0,
    speed: 500,
    arrows: false,
    customPaging: () => <div className="ft-slick__dots--custom"></div>,
  }

  const Slide1 = (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, width: '100%' }}>
      <Typography sx={{ fontSize: 72, mt: 2 }}>🎁</Typography>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'left' }}>
        <Box
          sx={{
            backgroundColor: colors.blueSky,
            color: 'white',
            fontSize: '20px',
            fontWeight: 700,
            width: 'fit-content',
            p: 1,
            py: 0.5,
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
          }}
        >
          Pour son bien-être
        </Box>
      </Box>
      <Typography sx={{ fontSize: 18, textAlign: 'center', color: theme.palette.secondary.main }}>
        Recevez une alimentation <br /> <b>sur-mesure conçue par des vétérinaires</b>
      </Typography>
      <img src="https://i.imgur.com/vWZIBxt.png" alt="Kit gratuit Caats" height={200} />
    </Box>
  )
  const Slide2 = (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, width: '100%' }}>
      <Box sx={{ position: 'relative', width: '100%', backgroundColor: '#FEFF99', height: '46px', mb: '30px' }}>
        <img
          src="https://i.imgur.com/Cue9LVF.png"
          alt="Logo de Caats"
          style={{ position: 'absolute', right: 20, bottom: -46 }}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'left' }}>
        <Box
          sx={{
            backgroundColor: colors.blueSky,
            color: 'white',
            fontSize: '20px',
            fontWeight: 700,
            width: 'fit-content',
            p: 1,
            py: 0.5,
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
          }}
        >
          Caats c&apos;est...
        </Box>
      </Box>
      <ul style={{ paddingLeft: 0, listStyleType: 'none' }}>
        <Typography component="li" variant="body1" sx={{ color: 'black', fontWeight: 'bold', mb: 2 }}>
          ✅ Un menu adapté à ses goûts et ses besoins
        </Typography>
        <Typography component="li" variant="body1" sx={{ color: 'black', fontWeight: 'bold', mb: 2 }}>
          🍗 Croquettes et pâtées super premiums
        </Typography>
        <Typography component="li" variant="body1" sx={{ color: 'black', fontWeight: 'bold', mb: 2 }}>
          ❤️ Approuvé par + 40 000 chats
        </Typography>
        <Typography component="li" variant="body1" sx={{ color: 'black', fontWeight: 'bold', mb: 2 }}>
          ‍⚕️ Crée par le Dr Denoun et le Professeur Nguyen
        </Typography>
        <Typography component="li" variant="body1" sx={{ color: 'black', fontWeight: 'bold', mb: 2 }}>
          🇷 Fabriqué en France
        </Typography>
      </ul>
    </Box>
  )
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 'calc(100dvh - 64px)',
        gap: 2,
      }}
    >
      <Box sx={{ mx: -2, maxWidth: '100%' }}>
        <Slider {...settings}>
          <div>{Slide1}</div>
          <div>{Slide2}</div>
        </Slider>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1, mt: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 0 }}>
            <Star sx={{ color: colors.amber, fontSize: 20 }} />
            <Star sx={{ color: colors.amber, fontSize: 20 }} />
            <Star sx={{ color: colors.amber, fontSize: 20 }} />
            <Star sx={{ color: colors.amber, fontSize: 20 }} />
            <StarHalf sx={{ color: colors.amber, fontSize: 20 }} />
          </Box>
          <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: blue }}>Noté 4,8/5</Typography>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Button variant="contained" color="primary" fullWidth sx={{ fontSize: '18px' }} onClick={onSubmit}>
          2 semaines gratuites
        </Button>
        <Button
          variant="text"
          sx={{
            borderColor: '#c5d2de',
            color: '#6b7990',
            textTransform: 'none',
            my: 1,
            width: '100%',
            fontSize: '18px',
            textDecoration: 'underline',
            ':hover': {
              backgroundColor: '#eef2f7',
            },
          }}
          onClick={onSkip}
        >
          Ne pas en bénéficier
        </Button>
      </Box>
    </Box>
  )
}
