import { Box, Typography } from '@mui/material'
import React from 'react'
import { theme } from '../../../theme'
import { LoadingBar } from '../LoadingBar'
import { AnimalDtoSpecies } from '../../../../interactors/gen/backendClient'

interface StepsProps {
  step: number
  species: AnimalDtoSpecies
}

export const Two: React.FC<StepsProps> = ({ step, species }) => {
  if (species === 'dog') {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: 2, height: '100%' }}>
        <LoadingBar step={step} />
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            fontSize: '22px',
            fontWeight: 700,
            width: 'fit-content',
            p: 1,
            py: 0.5,
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
          }}
        >
          Equipements et Alimentation
        </Box>
        <Typography variant="body1" sx={{ color: theme.palette.secondary.main, fontSize: '18px' }}>
          <b>Préparez l&apos;arrivée de votre chien : un budget initial d&apos;environ 100 € pour les essentiels</b>{' '}
          (gamelles, laisse, collier, jouets, etc.).
        </Typography>
        <ul style={{ paddingLeft: 20 }}>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            Un chien adulte doit boire entre 50 et 70 ml/kg par jour. <b>Changez son eau au moins 2 fois par jour.</b>
          </Typography>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            Optez pour une alimentation adaptée à son âge, taille et mode de vie.{' '}
            <b>Le coût annuel de l&apos;alimentation varie entre 280 et 1800 €</b>, selon la race et le régime choisi.
          </Typography>
        </ul>
      </Box>
    )
  } else {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: 2, height: '100%' }}>
        <LoadingBar step={step} />
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            fontSize: '22px',
            fontWeight: 700,
            width: 'fit-content',
            p: 1,
            py: 0.5,
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
          }}
        >
          Equipements et Alimentation
        </Box>
        <Typography variant="body1" sx={{ color: theme.palette.secondary.main, fontSize: '18px' }}>
          <b>Préparez l&apos;arrivée de votre chat : un budget initial d&apos;environ 100 € pour les essentiels</b>{' '}
          (gamelles, litière, jouets, griffoirs, etc.).
        </Typography>
        <ul style={{ paddingLeft: 0, listStyleType: 'none' }}>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            😼 Le grattage est naturel pour entretenir ses griffes et marquer son territoire.{' '}
            <b>Installez des griffoirs</b> près de ses zones de repos.
          </Typography>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            🚰 Assurez-lui une eau propre, changée deux fois par jour. <b>Une hydratation excessive</b> peut indiquer un
            problème de santé.
          </Typography>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            🥩 <b>Optez pour une alimentation de qualité,</b> adaptée à son âge et mode de vie. (~40€/mois)
          </Typography>
        </ul>
      </Box>
    )
  }
}
