import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'
import { AnimalDto } from '../../../../interactors/gen/backendClient'
import { getAnimalProfilePhotoSrc } from '../../../../utils/S3-links'
import { colors, theme } from '../../../theme'

interface StepsProps {
  animal: AnimalDto
  animalName: string
  certificateHandDelivered: boolean
}

export const Zero: React.FC<StepsProps> = ({ animal, animalName, certificateHandDelivered }) => {
  const profileImageKey = animal?.images?.profileImageKey || ''
  if (certificateHandDelivered) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: 3,
          height: '100%',
          backgroundColor: colors.blueSky,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Avatar
            src={profileImageKey ? getAnimalProfilePhotoSrc(profileImageKey) : ''}
            alt={animal?.name}
            sx={{ height: '140px', width: '140px' }}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            fontSize: '22px',
            fontWeight: 700,
            width: 'fit-content',
            p: 1,
            py: 0.5,
            pr: 4,
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
          }}
        >
          Vous adoptez {animalName}
        </Box>
        <Typography variant="body1" sx={{ color: 'white' }}>
          Depuis octobre 2022, Le certificat d&apos;engagement et de connaissance <b>est une obligation légale.</b> Il
          vise à sensibiliser et responsabiliser les acquéreurs d’animaux de compagnie.
        </Typography>
        <Typography variant="body1" sx={{ color: 'white' }}>
          Une fois signé, vous disposez d&apos;un délai de réflexion légale de 8 jours avant de signer le contrat
          d’adoption
        </Typography>
        <Typography variant="body1" sx={{ color: 'white', fontWeight: 700 }}>
          L’association vous a transmis le document en main propre
        </Typography>
      </Box>
    )
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 3,
        height: '100%',
        backgroundColor: colors.blueSky,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Avatar
          src={profileImageKey ? getAnimalProfilePhotoSrc(profileImageKey) : ''}
          alt={animal?.name}
          sx={{ height: '140px', width: '140px' }}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          fontSize: '22px',
          fontWeight: 700,
          width: 'fit-content',
          p: 1,
          py: 0.5,
          pr: 4,
          borderRadius: '11px',
          transform: 'rotate(-1deg)',
        }}
      >
        Vous adoptez {animalName}
      </Box>
      <Typography variant="body1" sx={{ color: 'white' }}>
        Depuis octobre 2022, Le certificat d&apos;engagement et de connaissance <b>est une obligation légale.</b>
      </Typography>
      <Typography variant="body1" sx={{ color: 'white' }}>
        Il vise à sensibiliser et responsabiliser les acquéreurs d’animaux de compagnie.
      </Typography>
      <Typography variant="body1" sx={{ color: 'white' }}>
        Une fois signé, vous disposerez d&apos;un délai de réflexion légale de 8 jours avant de signer le contrat
        d’adoption
      </Typography>
    </Box>
  )
}
