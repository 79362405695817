import React from 'react'
import { LoadingBlock } from './LoadingBlock'
import { Box, Typography } from '@mui/material'
import { theme } from '../../theme'

interface Props {
  step: number
}

export const LoadingBar: React.FC<Props> = ({ step }) => {
  const getColor = (step: number) => {
    switch (step) {
      case 1:
        return 'rgba(113, 177, 185, 0.57)'
      case 2:
        return 'rgba(255, 192, 0, 0.57)'
      case 3:
        return 'rgba(61, 158, 15, 0.28)'
      case 4:
        return 'rgba(113, 177, 185, 0.57)'
      case 5:
        return 'rgba(162, 208, 140, 1)'
      default:
        return 'blue'
    }
  }

  const getPercentage = (step: number) => {
    switch (step) {
      case 1:
        return '20%'
      case 2:
        return '40%'
      case 3:
        return '60%'
      case 4:
        return '80%'
      case 5:
        return ''
      default:
        return '0%'
    }
  }

  const color = getColor(step)
  const percentage = getPercentage(step)
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
      {Array.from({ length: step }).map((_, index) => (
        <LoadingBlock key={`loading-block-${index}`} color={color} starting={index === 0} ending={index === 4} />
      ))}
      <Typography variant="body1" sx={{ color: theme.palette.secondary.main, fontWeight: 700 }}>
        {percentage}
      </Typography>
    </Box>
  )
}
