import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { AnimalDto } from '../../../interactors/gen/backendClient'
import { blue, colors, theme } from '../../theme'
import { getReadableAnimalSterilizedOrNeutered, getReadableSex } from '../../../domain/Animal/AnimalDisplay'
import { calculateAge } from '../../../domain/Animal/calculateAge'
import dayjs from 'dayjs'
import { metricalpEvent } from '@metricalp/react'

interface Props {
  onSubmit: (answer?: 'yes' | 'no') => void
  animal: AnimalDto
  animalName: string
  alreadyAdopted?: boolean
  adopterId: string
  adopterName: string
}

export const Two: React.FC<Props> = ({ onSubmit, animal, animalName, alreadyAdopted, adopterId, adopterName }) => {
  const [redirected, setRedirected] = React.useState(false)

  const onRedirect = () => {
    metricalpEvent({
      type: `${alreadyAdopted ? 'adopted' : 'adoption'}_goto_caats`,
      adopterId: adopterId,
      adopterName: adopterName,
      animalName: animal?.name,
      animalId: animal?.id,
    })
    setRedirected(true)
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 'calc(100dvh - 64px)',
        gap: 1,
        p: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3,
          width: '100%',
        }}
      >
        {!redirected && <Typography sx={{ fontSize: 72 }}>🎉</Typography>}
        {redirected && (
          <Typography sx={{ fontSize: 18, fontWeight: 'bold', textAlign: 'center' }}>
            Voici les informations pour <br /> préparer son menu :
          </Typography>
        )}
        {!redirected && (
          <Box
            sx={{
              backgroundColor: colors.blueSky,
              color: 'white',
              fontSize: '20px',
              fontWeight: 700,
              width: 'fit-content',
              p: 1,
              py: 0.5,
              borderRadius: '11px',
              transform: 'rotate(-1deg)',
              textAlign: 'center',
            }}
          >
            Voici les informations pour <br /> préparer son menu :
          </Box>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', gap: 2, width: '100%', mt: 2 }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', color: blue }}>
              <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'inherit', lineHeight: '18px' }}>
                Nom
              </Typography>
              <Typography sx={{ fontSize: 14, color: 'inherit' }}>{animalName}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', color: blue }}>
              <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'inherit', lineHeight: '18px' }}>
                Sexe
              </Typography>
              <Typography sx={{ fontSize: 14, color: 'inherit' }}>
                {animal.sex ? getReadableSex(animal.sex) : '..'}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', color: blue }}>
              <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'inherit', lineHeight: '18px' }}>
                Stérilisé(e)
              </Typography>
              <Typography sx={{ fontSize: 14, color: 'inherit' }}>
                {animal.health.sterilizedOrNeutered
                  ? getReadableAnimalSterilizedOrNeutered(animal.health.sterilizedOrNeutered)
                  : '..'}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', color: blue }}>
            <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'inherit', lineHeight: '18px' }}>
              Âge - Date de naissance
            </Typography>
            <Typography sx={{ fontSize: 14, color: 'inherit' }}>
              {`${animal.birthday?.date ? calculateAge(animal.birthday.date) : '..'} - ${
                animal.birthday?.date ? dayjs(animal.birthday.date).format('DD/MM/YYYY') : '..'
              }`}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', color: blue }}>
            <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'inherit', lineHeight: '18px' }}>
              Race
            </Typography>
            <Typography sx={{ fontSize: 14, color: 'inherit' }}>
              {animal.breedData?.breed ?? 'Race inconnue - Optez pour “Européen”'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', color: blue }}>
            <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'inherit', lineHeight: '18px' }}>
              Poids
            </Typography>
            <Typography sx={{ fontSize: 14, color: 'inherit' }}>
              {animal.weightInKgs ? animal.weightInKgs + 'kg' : 'Un chat de taille moyenne pèse 4,5kg'}
            </Typography>
          </Box>
        </Box>
      </Box>
      {!redirected ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt: 4 }}>
          <Button
            href="https://app.caats.co/formulaire/check?coupon=PETSO"
            target="_blank"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ fontSize: '18px' }}
            onClick={() => onRedirect()}
          >
            Recevoir mon kit
          </Button>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          {/* <Typography sx={{ fontSize: 72, mt: 1 }}>🎉</Typography> */}
          <Box
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: 'white',
              fontSize: '20px',
              fontWeight: 700,
              width: 'fit-content',
              p: 1,
              py: 0.5,
              borderRadius: '11px',
              transform: 'rotate(-1deg)',
              textAlign: 'center',
              mb: 6,
            }}
          >
            Avez-vous aimé cette offre pour {animalName} ?
          </Box>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            sx={{ fontSize: '18px' }}
            onClick={() => onSubmit('yes')}
          >
            Oui
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            sx={{ fontSize: '18px', mt: 2 }}
            onClick={() => onSubmit('no')}
          >
            Non
          </Button>
        </Box>
      )}
    </Box>
  )
}
