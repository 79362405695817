import { Box, Collapse, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import {
  getReadableAnimalActivity,
  getReadableAnimalCorpulence,
  getReadableAnimalHealthTestStatus,
  getReadableAnimalSize,
  getReadableAnimalSterilizedOrNeutered,
  getReadableAnimalVaccineStatus,
  getReadableTakeOverReason,
} from '../../domain/Animal/AnimalDisplay'
import { AnimalDto } from '../../interactors/gen/backendClient'
import { ReactComponent as AddressBookIcon } from '../icons/AddressBookIcon.svg'
import { ReactComponent as AmbulanceIcon } from '../icons/AmbulanceIcon.svg'
import { ReactComponent as ArrowDownIcon } from '../icons/ArrowDownIcon.svg'
import { ReactComponent as BirthdayCakeIcon } from '../icons/BirthdayCakeIcon.svg'
import { ReactComponent as HomeIcon } from '../icons/HomeIcon.svg'
import { ReactComponent as HomeLocationIcon } from '../icons/HomeLocationIcon.svg'
import { theme } from '../theme'
import { AdoptionFurtherInfoChipsItem } from './AdoptionFurtherInfoChipsItem'
import { AdoptionFurtherInfoItem } from './AdoptionFurtherInfoItem'

interface Props {
  animal: AnimalDto
  defaultExpanded?: boolean
  hasControls?: boolean
}

export const AdoptionFurtherInfo: React.FC<Props> = ({ animal, defaultExpanded, hasControls = true }) => {
  const genealogyText = [
    { key: 'Enregistrement LOF', value: animal.breedData?.isPedigreeRegistered ? 'LOF' : 'Non-LOF' },
    {
      key: 'N° LOOF',
      value: animal.breedData?.registrationNumber ? animal.breedData?.registrationNumber.toString() : 'N/A',
    },
    {
      key: 'N° animaux dans la portée',
      value: animal.breedData?.numberOfAnimalsInLitter ? animal.breedData?.numberOfAnimalsInLitter.toString() : 'N/A',
    },
  ]
  const activityText = [
    {
      key: 'Poids',
      value:
        animal.formAndActivity?.weight && animal.formAndActivity.weight.length > 0
          ? `${animal.formAndActivity.weight[animal.formAndActivity.weight.length - 1].weightInKgs}kg`
          : 'N/A',
    },
    {
      key: 'Taille',
      value: animal.formAndActivity?.size ? getReadableAnimalSize(animal.formAndActivity.size) : 'N/A',
    },
    {
      key: 'Corpulence',
      value: animal.formAndActivity?.corpulence
        ? getReadableAnimalCorpulence(animal.formAndActivity.corpulence)
        : 'N/A',
    },
    {
      key: 'Activité',
      value: animal.formAndActivity?.activity ? getReadableAnimalActivity(animal.formAndActivity.activity) : 'N/A',
    },
  ]
  const takeOverText = [
    { key: 'Date', value: dayjs(animal.takeOver.takeOverAt).format('DD/MM/YYYY') ?? 'N/A' },
    {
      key: 'Ville',
      value: animal.takeOver?.cityOfOrigin ?? 'N/A',
    },
    {
      key: 'Motif',
      value: animal.takeOver.reason ? getReadableTakeOverReason(animal.takeOver.reason) : 'N/A',
    },
  ]
  const healthText = [
    {
      key: 'Statut vaccinal',
      value: animal.vaccination.vaccinationStatus
        ? getReadableAnimalVaccineStatus(animal.vaccination.vaccinationStatus)
        : 'N/A',
    },
    {
      key: 'Stérilisé(e)',
      value: animal.health.sterilizedOrNeutered
        ? getReadableAnimalSterilizedOrNeutered(animal.health.sterilizedOrNeutered)
        : 'N/A',
    },
    {
      key: 'Test FIV',
      value: animal.health.FIV ? getReadableAnimalHealthTestStatus(animal.health.FIV) : 'N/A',
    },
    {
      key: 'Test FeLV',
      value: animal.health.FeLV ? getReadableAnimalHealthTestStatus(animal.health.FeLV) : 'N/A',
    },
  ]

  const nextDewormingDate = animal.health?.operations?.find(
    (operation) => operation.type.includes('deworming') && dayjs(operation.date).isAfter(dayjs())
  )?.date

  const nextFleaControlDate = animal.health?.operations?.find(
    (operation) => operation.type.includes('flea-control') && dayjs(operation.date).isAfter(dayjs())
  )?.date

  const nextVaccineDate = animal.health?.operations?.find(
    (operation) =>
      (operation.type.includes('annual-reminder') ||
        operation.type.includes('second-injection') ||
        operation.type.includes('third-injection')) &&
      dayjs(operation.date).isAfter(dayjs())
  )?.date

  const [expanded, setExpanded] = useState(defaultExpanded)

  useEffect(() => {
    setExpanded(defaultExpanded)
  }, [defaultExpanded])

  return (
    <>
      <Box
        onClick={() => (hasControls ? setExpanded(!expanded) : null)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'left',
          cursor: 'pointer',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%', my: 2 }}>
          <Typography
            variant="body1"
            sx={{
              fontSize: '19px',
              lineHeight: '19.2px',
              fontWeight: 700,
              textDecoration: 'underline',
              fontStyle: 'italic',
            }}
          >
            Prochaines échéances à venir :
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" fontStyle="italic" fontSize={13} color="rgba(87, 87, 87, 1)">
                Vermifuge
              </Typography>
              <Typography variant="body1" fontWeight={700} fontSize={15}>
                {nextDewormingDate ? dayjs(nextDewormingDate).format('DD/MM/YYYY') : 'N/A'}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" fontStyle="italic" fontSize={13} color="rgba(87, 87, 87, 1)">
                Antiparasitaire
              </Typography>
              <Typography variant="body1" fontWeight={700} fontSize={15}>
                {nextFleaControlDate ? dayjs(nextFleaControlDate).format('DD/MM/YYYY') : 'N/A'}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" fontStyle="italic" fontSize={13} color="rgba(87, 87, 87, 1)">
                Vaccin annuel
              </Typography>
              <Typography variant="body1" fontWeight={700} fontSize={15}>
                {nextVaccineDate ? dayjs(nextVaccineDate).format('DD/MM/YYYY') : 'N/A'}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Typography variant="h2" component="p">
          Général
        </Typography>

        {hasControls && (
          <ArrowDownIcon
            style={{
              transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
              marginLeft: 'auto',
              transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
              }),
            }}
          />
        )}
      </Box>
      <Collapse in={expanded} timeout="auto">
        <Grid container spacing={2} sx={{ mt: 0 }}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <AdoptionFurtherInfoItem
                icon={<BirthdayCakeIcon fill="#333333" />}
                title="Portée et généalogie"
                text={genealogyText}
              />
              <AdoptionFurtherInfoItem
                icon={<AddressBookIcon fill="#333333" />}
                title="Forme et activité"
                text={activityText}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <AdoptionFurtherInfoItem
                icon={<HomeIcon fill="#333333" />}
                title="Contexte de prise en charge"
                text={takeOverText}
              />
              <AdoptionFurtherInfoItem
                icon={<AmbulanceIcon fill="#333333" />}
                title="Suivi de santé"
                text={healthText}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <AdoptionFurtherInfoChipsItem
              icon={<HomeLocationIcon />}
              title="Caractéristiques"
              text={animal.characteristics || ['Aucune caractéristique renseignée']}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  )
}
