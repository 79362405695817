import { zodResolver } from '@hookform/resolvers/zod'
import { Avatar, Box, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { AnimalDto } from '../../../interactors/gen/backendClient'
import { getAnimalProfilePhotoSrc } from '../../../utils/S3-links'
import { theme } from '../../theme'
import { getEmojiSpecies } from '../../../domain/Animal/AnimalDisplay'

interface Props {
  animal: AnimalDto
  onSubmit: (data: NewNameFormData) => void
}

const newNameSchema = z.object({
  name: z.string().min(1, 'Le nom est requis'),
})

export type NewNameFormData = z.infer<typeof newNameSchema>

export const One: React.FC<Props> = ({ animal, onSubmit }) => {
  const profileImageKey = animal?.images?.profileImageKey || ''

  const {
    control: nameControl,
    handleSubmit: handleNameSubmit,
    formState: { errors: nameErrors },
  } = useForm<NewNameFormData>({
    resolver: zodResolver(newNameSchema),
    defaultValues: { name: animal.name },
  })
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 4, height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Avatar
          src={profileImageKey ? getAnimalProfilePhotoSrc(profileImageKey) : ''}
          alt={`Photo de ${animal.name}`}
          sx={{ height: '140px', width: '140px', fontSize: '72px', backgroundColor: 'white' }}
        >
          {getEmojiSpecies(animal.species)}
        </Avatar>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', gap: 1 }}>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            fontSize: '20px',
            fontWeight: 700,
            width: 'fit-content',
            p: 1,
            py: 0.5,
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
          }}
        >
          Vous avez adopté {animal.name}
        </Box>
        <Typography variant="body1" sx={{ color: 'white' }}>
          En partenariat avec l’association, vous avez la chance de bénéficier de son profil pour vous accompagner tout
          au long de sa vie !
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', gap: 1 }}>
        <Typography variant="body1" sx={{ color: 'white' }}>
          <b>D’ailleurs {animal.name} a un nouveau nom ?</b> <br /> Commençons déjà par{' '}
          {animal.sex === 'male' ? 'le' : 'la'} rebaptiser :
        </Typography>
        <form
          onSubmit={handleNameSubmit((data) => onSubmit(data))}
          id="newNameForm"
          style={{ width: '100%', maxWidth: '460px' }}
        >
          <Controller
            name="name"
            control={nameControl}
            render={({ field }) => (
              <TextField
                {...field}
                label="Nom"
                fullWidth
                margin="normal"
                error={!!nameErrors.name}
                helperText={nameErrors.name ? nameErrors.name.message : ''}
                sx={{ '& input': { color: 'white' }, '& label': { color: 'white' } }}
              />
            )}
          />
        </form>
      </Box>
    </Box>
  )
}
