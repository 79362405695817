import { Lock } from '@mui/icons-material'
import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AdoptionAttemptDto, AnimalDto } from '../../interactors/gen/backendClient'
import { blueSky, lightRed } from '../theme'
import { AdoptionFurtherInfo } from './AdoptionFurtherInfo'
import { DocumentsTab } from './DocumentsTab'
import { SmallAnimalCard } from './SmallAnimalCard'
import { metricalpEvent } from '@metricalp/react'

export enum Categories {
  Profil = 'informations',
  Documents = 'documents',
}

interface Props {
  animalProfile: AnimalDto
  attempt: AdoptionAttemptDto
  assoEmail: string
  adopterId: string
  adopterName: string
  alreadyAdopted?: boolean
}

export const AdoptionTabs: React.FC<Props> = ({
  animalProfile,
  attempt,
  assoEmail,
  alreadyAdopted,
  adopterId,
  adopterName,
}) => {
  const [selectedCategory, setSelectedCategory] = React.useState<Categories>(Categories.Profil)

  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(window.location.search)
  const handleCategoryChange = (category: Categories) => {
    if (category === 'documents') {
      metricalpEvent({
        type: `${alreadyAdopted ? 'adopted' : 'adoption'}_profil_document`,
        adopterId: adopterId,
        adopterName: adopterName,
        animalName: animalProfile?.name,
        animalId: animalProfile?.id,
      })
    }
    setSelectedCategory(category)
    navigate(`${location.pathname}?page=profil&${`tab=${category}`}`)
  }

  const handleLeave = () => {
    navigate(location.pathname)
  }

  function a11yProps(tabKey: Categories) {
    return {
      id: `simple-tab-${tabKey}`,
      value: tabKey,
      'aria-controls': `simple-tabpanel-${tabKey}`,
    }
  }

  const animalName = attempt.newName || animalProfile.name

  useEffect(() => {
    const tab = queryParams.get('tab') as Categories
    if (tab) {
      setSelectedCategory(tab)
    } else {
      setSelectedCategory(Categories.Profil)
    }
  }, [queryParams])

  return (
    <Grid container spacing={3} sx={{ pb: 4, pt: 1 }}>
      <Box
        sx={{
          position: 'fixed',
          top: '64',
          left: '0',
          right: '0',
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          backgroundColor: lightRed,
          p: '16px',
          width: '100%',
          mt: 2,
          zIndex: 100,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px', pl: { xs: 0, md: '240px' } }}>
          <Lock />
          {(attempt?.contract?.signed && (attempt?.paymentSentByUser || attempt?.paymentDone)) || attempt?.done ? (
            <Link
              to={location.pathname}
              style={{
                fontSize: '14px',
                lineHeight: '16.8px',
                color: '#696b6d',
                fontWeight: 600,
              }}
            >
              Débloquez le profil pour le compléter
            </Link>
          ) : (
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '16.8px',
                color: '#696b6d',
                fontWeight: 600,
              }}
            >
              Vous pourrez modifier le profil une fois adopté
            </Typography>
          )}
        </Box>
      </Box>

      <Grid item xs={12} md={3.5}>
        <div style={{ height: '64px' }} />
        <Box sx={{ mt: { xs: 0, md: 6 } }}>
          <SmallAnimalCard
            animal={animalProfile}
            assoEmail={assoEmail}
            animalName={animalName}
            adopterName={adopterName}
            adopterId={adopterId}
            alreadyAdopted={alreadyAdopted || false}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={8.5}>
        <Tabs
          value={selectedCategory}
          onChange={(_, tab) => handleCategoryChange(tab as Categories)}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="basic tabs example"
        >
          {Object.values(Categories).map((category) => (
            <Tab key={`animal-profil-${category}`} label={translateCategories(category)} {...a11yProps(category)} />
          ))}
        </Tabs>

        <Button
          variant="contained"
          onClick={handleLeave}
          sx={{
            position: 'fixed',
            bottom: '48px',
            right: '24px',
            left: { xs: '24px', md: '264px' },
            fontSize: 18,
            fontWeight: 700,
            backgroundColor: blueSky,
            '&:hover': {
              backgroundColor: blueSky,
            },
            zIndex: 100,
          }}
        >
          Retour
        </Button>

        <Box sx={{ mt: 1 }}>
          <TabPanel value={selectedCategory} index={'informations' as Categories}>
            <AdoptionFurtherInfo animal={animalProfile} defaultExpanded hasControls={false} />
          </TabPanel>
          <TabPanel value={selectedCategory} index={'documents' as Categories}>
            <DocumentsTab attempt={attempt} animal={animalProfile} animalName={animalName} />
          </TabPanel>
        </Box>
        <div style={{ height: '72px' }} />
      </Grid>
    </Grid>
  )
}

function translateCategories(category: Categories): string {
  switch (category) {
    case Categories.Profil:
      return 'Profil'
    case Categories.Documents:
      return 'Documents'
    default:
      return ''
  }
}

interface TabPanelProps<T> {
  children?: React.ReactNode
  index: T
  value: T
}

function TabPanel<T>(props: TabPanelProps<T>) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}
