import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import React from 'react'
import { colors } from '../theme'

interface AdoptionModalProps {
  open: boolean
  handleClose: () => void
}

export const AdoptionPriceDetailsModal: React.FC<AdoptionModalProps> = ({ open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="adoption-modal-title"
      sx={{
        '& .MuiDialog-paper': {
          width: '100%', // Ensures it takes up the full width
          maxHeight: '100%', // Prevents overflow
          margin: 0, // Removes any default margin
        },
      }}
    >
      {/* Dialog Content */}
      <DialogContent
        sx={{
          padding: '8px', // Reduced padding
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexGrow: 1, // Ensures the content stretches
        }}
      >
        {/* Header Box */}
        <Box
          sx={{
            backgroundColor: colors.blueSky,
            color: 'white',
            fontSize: '20px',
            fontWeight: 700,
            width: 'fit-content',
            p: 1,
            py: 0.5,
            pr: 2,
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
            marginBottom: '24px',
          }}
        >
          💡 Votre contribution
        </Box>

        {/* Text Content */}
        <Box>
          <Typography variant="body1" sx={{ mb: 2, fontStyle: 'italic' }}>
            Petso est un outil que nous mettons à disposition gratuitement en France pour favoriser l’accueil des
            animaux abandonnés et leur adoption
            <br /> <br />
            Au délà de la gratuité, nous sommes soumis à <b>des frais de signature électronique et de paiement</b> pour
            lequel nous sollicitons votre soutien !
          </Typography>
        </Box>
      </DialogContent>

      {/* Dialog Actions */}
      <DialogActions sx={{ padding: '8px' }}>
        {' '}
        {/* Reduced padding in the actions area */}
        <Button onClick={handleClose} variant="contained" color="primary" fullWidth sx={{ fontSize: '18px' }}>
          J&apos;ai compris
        </Button>
      </DialogActions>
    </Dialog>
  )
}
