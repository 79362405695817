import { zodResolver } from '@hookform/resolvers/zod'
import { Lock, Mail, Person, Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, Checkbox, Divider, Grid, IconButton, Tab, Tabs, TextField, Typography, styled } from '@mui/material'
import { StatusCodes } from 'http-status-codes'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import * as zod from 'zod'
import { AuthService } from '../../interactors/services/AuthService'

import { LoadingButton } from '@mui/lab'
import { getBackendBaseUrl } from '../../interactors/clients/client'
import { useAccountsStore } from '../../store/AccountsStore'
import { PATHS } from '../PATHS'
import { blue, colors, theme } from '../theme'
import image from '../Tutorial/Steps/bienvenueSurPetso.png'

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderRadius: '8px',
  padding: '6px',
  background: theme.palette.background.paper,
  boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  margin: theme.spacing(1, 0),
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    justifyContent: 'space-around',
    p: 1,
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}))

const StyledTab = styled(Tab)(({ theme }) => ({
  flex: 1,
  transition: 'all 0.2s ease-out',
  borderRadius: '4px',
  color: theme.palette.text.secondary,
  paddingTop: '10px',
  paddingBottom: '10px',
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.action.selected,
  },
  '&:not(:last-of-type)': {
    marginRight: theme.spacing(1),
  },
}))

export const LoginPage: React.FC = () => {
  const emailParam = new URLSearchParams(window.location.search).get('email')
  const modeParam = new URLSearchParams(window.location.search).get('mode')
  const from = new URLSearchParams(window.location.search).get('from')
  const fromParam = from ? `/${from}` : undefined
  const navigate = useNavigate()
  const accountsStore = useAccountsStore()

  const [isRegister, setIsRegister] = useState(modeParam === 'register')
  const [serverError, setServerError] = useState('')
  const [isPasswordField, setIsPasswordField] = useState(true)

  const atLeastTwoWords = (str: string) => {
    return str.trim().split(/\s+/).length >= 2
  }

  const schema = zod.object({
    fullname: isRegister
      ? zod.string().refine(atLeastTwoWords, { message: 'Merci de renseigner votre nom complet (prénom + nom)' })
      : zod.string().optional(),
    email: zod.string().email('Merci de renseigner une adresse email valide'),
    password: isRegister ? zod.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères') : zod.string(),
    cgu: isRegister
      ? zod.boolean().refine((value) => value, { message: "Merci d'accepter les CGU" })
      : zod.boolean().optional(),
  })

  type FormParams = zod.z.infer<typeof schema>

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<FormParams>({
    resolver: zodResolver(schema),
  })

  useEffect(() => {
    if (emailParam) {
      return setValue('email', emailParam)
    }
    if (AuthService.isLogged()) {
      navigate(fromParam ?? '/')
    }
  })

  const onSubmit = async (data: FormParams): Promise<void> => {
    setServerError('')
    const { email, password, fullname } = data

    if (isRegister) {
      if (!fullname) return
      await AuthService.register(email.trim(), password.trim(), fullname.trim())
        .then(() => {
          if (AuthService.account) {
            accountsStore.changeConnectedAccount(AuthService.account)
          }

          navigate(fromParam ?? '/')
        })
        .catch((error) => {
          console.error({ error })
          if (error.response.status === StatusCodes.CONFLICT) {
            setServerError('Cet email est déjà utilisé.')
          } else {
            setServerError('Une erreur est survenue.')
          }
        })
    } else {
      await AuthService.loginWithPassword(email.trim(), password.trim())
        .then(() => {
          if (AuthService.account) {
            accountsStore.changeConnectedAccount(AuthService.account)
          }

          navigate(fromParam ? fromParam.replace('-', '&') : '/')
        })
        .catch((error) => {
          console.error({ error })
          if (error.response.status === StatusCodes.UNAUTHORIZED) {
            setServerError('Mot de passe incorrect.')
          } else {
            setServerError('Une erreur est survenue.')
          }
        })
    }
  }

  const handleLoginWithGoogle = async (): Promise<void> => {
    setServerError('')

    window.location.href = `${getBackendBaseUrl()}/v1/adopters-platform/auth/google`
  }

  return (
    <Box
      sx={{
        bgcolor: colors.blueSky,
        display: 'flex',
        flexDirection: { md: 'row', xs: 'column' },
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        maxHeight: '100%',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: { md: '100%', xs: 'min-content' },
          width: { md: '50%', xs: '100%' },
          mt: { md: '30vh', xs: 0 },
        }}
      >
        <Box flexGrow="1" margin="auto" padding="0px 2%" boxSizing="border-box" paddingLeft={{ lg: 10 }}>
          <Box sx={{ bgcolor: 'white', borderRadius: '8px', p: 1, px: 2, boxShadow: 4 }}>
            <Box
              sx={{
                margin: 'auto',
                mt: { md: '0', xs: '0.5rem' },
                color: 'white',
              }}
            >
              <Typography
                variant="h1"
                sx={{ color: blue, fontFamily: 'Insaniburger', fontSize: { xs: '40px', sm: '55px' } }}
              >
                Bienvenue sur Petso !
              </Typography>
              <Typography
                variant="h4"
                sx={{ color: blue, fontWeight: 600, fontSize: { xs: '1rem', sm: '1.4rem' }, marginBottom: 2 }}
              >
                {'Votre espace pour vos animaux'}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ width: '100%', maxWidth: '400px' }}>
                <button
                  className="gsi-material-button"
                  style={{ width: '100%' }}
                  onClick={() => handleLoginWithGoogle()}
                >
                  <div className="gsi-material-button-state"></div>
                  <div className="gsi-material-button-content-wrapper">
                    <div className="gsi-material-button-icon">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        style={{ display: 'block' }}
                      >
                        <path
                          fill="#EA4335"
                          d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                        ></path>
                        <path
                          fill="#4285F4"
                          d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                        ></path>
                        <path
                          fill="#FBBC05"
                          d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                        ></path>
                        <path
                          fill="#34A853"
                          d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                        ></path>
                        <path fill="none" d="M0 0h48v48H0z"></path>
                      </svg>
                    </div>
                    <span className="gsi-material-button-contents">Se connecter avec Google</span>
                    <span style={{ display: 'none' }}>Se connecter avec Google</span>
                  </div>
                </button>
                <Divider sx={{ color: 'black', marginTop: '1rem', marginBottom: '1rem' }}>OU</Divider>
                <StyledTabs
                  value={isRegister ? 1 : 0}
                  onChange={(_, newValue) => setIsRegister(newValue === 1)}
                  variant="fullWidth"
                  aria-label="login or register selection"
                >
                  <StyledTab label="Se connecter" />
                  <StyledTab label="S'inscrire" />
                </StyledTabs>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {isRegister && (
                    <Box sx={{ my: '1.5rem' }}>
                      <TextField
                        fullWidth
                        label="Nom complet"
                        placeholder="John Doe"
                        {...register('fullname')}
                        error={!!errors.fullname}
                        helperText={errors.fullname?.message as string}
                        InputProps={{
                          startAdornment: (
                            <div style={{ marginRight: '8px' }}>
                              <Person />
                            </div>
                          ),
                        }}
                      />
                    </Box>
                  )}

                  <Box sx={{ my: '1.5rem' }}>
                    <TextField
                      fullWidth
                      label="Email"
                      placeholder="johndoe@gmail.com"
                      {...register('email')}
                      error={!!errors.email}
                      helperText={errors.email?.message as string}
                      InputProps={{
                        startAdornment: (
                          <div style={{ marginRight: '8px' }}>
                            <Mail />
                          </div>
                        ),
                      }}
                    />
                  </Box>

                  <Box sx={{ my: '1.5rem', mb: isRegister ? 0 : '1.5rem' }}>
                    <TextField
                      fullWidth
                      type={isPasswordField ? 'password' : 'text'}
                      label="Mot de passe"
                      placeholder="********"
                      {...register('password')}
                      error={!!errors.password}
                      helperText={errors.password?.message as string}
                      InputProps={{
                        startAdornment: (
                          <div style={{ marginRight: '8px' }}>
                            <Lock />
                          </div>
                        ),
                        endAdornment: (
                          <IconButton
                            color="default"
                            size="small"
                            edge="end"
                            onClick={() => setIsPasswordField(!isPasswordField)}
                          >
                            {isPasswordField ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        ),
                      }}
                    />
                  </Box>

                  {isRegister && (
                    <Box sx={{ mt: 0, mb: '1.5rem', display: 'flex', flexDirection: 'column' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        <Checkbox {...register('cgu')} />
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          J&#39;accepte les{' '}
                          <a
                            href="https://petso.fr/conditions-generales-d-utilisation"
                            target="_blank"
                            rel="noreferrer"
                          >
                            CGU
                          </a>
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ color: theme.palette.error.main, pl: '9px', fontSize: '12px' }}>
                        {errors.cgu?.message as string}
                      </Typography>
                    </Box>
                  )}

                  <div>
                    {serverError && (
                      <Grid item>
                        <Typography color="error">{serverError}</Typography>
                      </Grid>
                    )}
                    <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem">
                      <Box position="relative" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {!isRegister && <Link to={PATHS.requestPasswordReset.absolute}>Mot de passe oublié ?</Link>}
                        <LoadingButton
                          variant="contained"
                          id="submit-button"
                          type="submit"
                          loading={isSubmitting}
                          sx={{ py: 2, px: 4 }}
                        >
                          {isRegister ? `S'inscrire` : 'Se connecter'}
                        </LoadingButton>
                      </Box>
                    </Box>
                  </div>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          zIndex: 1,
          pointerEvents: 'none',
          display: { xs: 'none', md: 'flex' },
          flexDirection: 'column',
          alignItems: 'center',
          height: { md: '100%', xs: '100%' },
          width: { md: '50%', xs: '100%' },
          mt: { md: '10vh', xs: 0 },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
          <img src={image} width={300} alt="Image d'illustration entre une femme et son animal" />
        </Box>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            fontSize: '20px',
            fontWeight: 700,
            width: 'fit-content',
            p: 1,
            py: 0.5,
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
            my: 2,
          }}
        >
          L’espace pour adopter et prendre soin de votre animal
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
            <Typography variant="body1" color="white" fontSize={38}>
              🧸
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" color="white" fontWeight={700} fontSize={17}>
                Adoptez votre animal auprès d’associations partenaires
              </Typography>
              <Typography variant="body1" color="white" fontStyle="italic" fontSize={14}>
                confirmation du souhait, transmission et signature des documents, règlements...
              </Typography>
            </Box>
          </Box>
          <Typography variant="body1" color="white" fontWeight={700} fontSize={17}>
            Vous avez déjà adopté ? <br />
            En partenariat avec l’association, un espace vous est dédié pour :
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
            <Typography variant="body1" color="white" fontSize={38}>
              📑
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" color="white" fontWeight={700} fontSize={17}>
                Ses documents
              </Typography>
              <Typography variant="body1" color="white" fontStyle="italic" fontSize={14}>
                liés à l’adoption et à sa santé
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
            <Typography variant="body1" color="white" fontSize={38}>
              🏥
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" color="white" fontWeight={700} fontSize={17}>
                Ses rappels de santé
              </Typography>
              <Typography variant="body1" color="white" fontStyle="italic" fontSize={14}>
                vaccinations, vermifuges, antiparasitaires
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
            <Typography variant="body1" color="white" fontSize={38}>
              🎁
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" color="white" fontWeight={700} fontSize={17}>
                De conseils & recommandations
              </Typography>
              <Typography variant="body1" color="white" fontStyle="italic" fontSize={14}>
                Et des cadeaux de bienvenue !
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
            <Typography variant="body1" color="white" fontSize={38}>
              🧑‍⚕️
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" color="white" fontWeight={700} fontSize={17}>
                L&apos;accès à des experts
              </Typography>
              <Typography variant="body1" color="white" fontStyle="italic" fontSize={14}>
                Réservations, téléconsultations, messagerie...
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
            <Typography variant="body1" color="white" fontSize={38}>
              😻️
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" color="white" fontWeight={700} fontSize={17}>
                Compléter son profil en continu
              </Typography>
              <Typography variant="body1" color="white" fontStyle="italic" fontSize={14}>
                Et ajouter vos autres animaux !
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
