import { Lock, Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, IconButton, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { z } from 'zod'
import { useForm, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { AuthClient } from '../../interactors/clients/AuthClient'
import { AuthService } from '../../interactors/services/AuthService'
import { useAccountsStore } from '../../store/AccountsStore'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import { StatusCodes } from 'http-status-codes'
import { PATHS } from '../PATHS'
import { blue, colors, theme } from '../theme'
import image from '../Tutorial/Steps/bienvenueSurPetso.png'

interface ErrorMessage {
  message: string
}

// Zod schema for validation
const passwordSchema = z
  .object({
    password: z.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères.'),
    passwordConfirm: z.string(),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: 'Les mots de passe ne correspondent pas.',
    path: ['passwordConfirm'],
  })

type PasswordFormData = z.infer<typeof passwordSchema>

export const CreatePasswordPage: React.FC = () => {
  const accountsStore = useAccountsStore()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const navigate = useNavigate()
  const [isPasswordField, setIsPasswordField] = useState(true)

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<PasswordFormData>({
    resolver: zodResolver(passwordSchema),
    mode: 'onSubmit',
  })

  const createPasswordMutation = useMutation<string, AxiosError<ErrorMessage>, { token: string; newPassword: string }>(
    async ({ token, newPassword }) => {
      return await AuthClient.createPassword(token, newPassword)
    },
    {
      onSuccess: (email) => {
        globalSnackbarStore.triggerSuccessMessage(`Votre accès a bien été créé !`)
        AuthService.loginWithPassword(email.trim(), watch('password').trim())
          .then(() => {
            if (AuthService.account) {
              accountsStore.changeConnectedAccount(AuthService.account)
              navigate('/')
            }
          })
          .catch((error) => {
            if (error.response.status === StatusCodes.UNAUTHORIZED) {
              globalSnackbarStore.triggerErrorMessage('Mot de passe incorrect.')
            } else {
              globalSnackbarStore.triggerErrorMessage('Une erreur est survenue.')
            }
          })
      },
      onError: (error: AxiosError<ErrorMessage>) => {
        globalSnackbarStore.triggerErrorMessage(
          `Une erreur est survenue lors de la création de votre mot de passe : ${
            error.response?.data?.message ?? 'Erreur inconnue'
          }`
        )
      },
    }
  )

  const onSubmit: SubmitHandler<PasswordFormData> = ({ password }) => {
    const token = new URLSearchParams(window.location.search).get('token')
    if (!token) {
      globalSnackbarStore.triggerErrorMessage('Token invalide.')
      return
    }

    createPasswordMutation.mutate({ token, newPassword: password })
  }

  return (
    <Box
      sx={{
        bgcolor: colors.blueSky,
        display: 'flex',
        flexDirection: { md: 'row', xs: 'column' },
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        maxHeight: '100%',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: { md: '100%', xs: 'min-content' },
          width: { md: '50%', xs: '100%' },
          mt: { md: '30vh', xs: 0 },
        }}
      >
        <Box flexGrow="1" margin="auto" padding="0px 2%" boxSizing="border-box" paddingLeft={{ lg: 10 }}>
          <Box sx={{ bgcolor: 'white', borderRadius: '8px', p: 1, px: 2, boxShadow: 4 }}>
            <Box
              sx={{
                margin: 'auto',
                mt: { md: '0', xs: '0.5rem' },
                color: 'white',
              }}
            >
              <Typography
                variant="h1"
                sx={{ color: blue, fontFamily: 'Insaniburger', fontSize: { xs: '40px', sm: '55px' } }}
              >
                Bienvenue sur Petso !
              </Typography>
              <Typography
                variant="h4"
                sx={{ color: blue, fontWeight: 600, fontSize: { xs: '1rem', sm: '1.4rem' }, marginBottom: 2 }}
              >
                {'Créez votre mot de passe pour accéder à votre espace.'}
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  fullWidth
                  type={isPasswordField ? 'password' : 'text'}
                  label="Mot de passe"
                  {...register('password')}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  InputProps={{
                    startAdornment: <Lock sx={{ marginRight: '8px' }} />,
                    endAdornment: (
                      <IconButton
                        color="default"
                        size="small"
                        edge="end"
                        onClick={() => setIsPasswordField(!isPasswordField)}
                      >
                        {isPasswordField ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />
                <TextField
                  fullWidth
                  type={isPasswordField ? 'password' : 'text'}
                  label="Confirmation du mot de passe"
                  {...register('passwordConfirm')}
                  error={!!errors.passwordConfirm}
                  helperText={errors.passwordConfirm?.message}
                  sx={{ marginTop: '1.5rem' }}
                />
                <Box display="flex" justifyContent="center" alignItems="center" marginTop="1rem">
                  <Box position="relative" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant="body1" sx={{ textAlign: 'center', color: 'black' }}>
                      J&#39;ai déjà créé mon mot de passe: <Link to={PATHS.login.absolute}>Me connecter</Link>
                    </Typography>
                    <LoadingButton
                      variant="contained"
                      id="login-button"
                      type="submit"
                      loading={createPasswordMutation.isLoading}
                      sx={{ py: 2, px: 4 }}
                    >
                      Créer mon compte
                    </LoadingButton>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          zIndex: 1,
          pointerEvents: 'none',
          display: { xs: 'none', md: 'flex' },
          flexDirection: 'column',
          alignItems: 'center',
          height: { md: '100%', xs: '100%' },
          width: { md: '50%', xs: '100%' },
          mt: { md: '10vh', xs: 0 },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
          <img src={image} width={300} alt="Image d'illustration entre une femme et son animal" />
        </Box>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            fontSize: '20px',
            fontWeight: 700,
            width: 'fit-content',
            p: 1,
            py: 0.5,
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
            my: 2,
          }}
        >
          L’espace pour adopter et prendre soin de votre animal
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
            <Typography variant="body1" color="white" fontSize={38}>
              🧸
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" color="white" fontWeight={700} fontSize={17}>
                Adoptez votre animal auprès d’associations partenaires
              </Typography>
              <Typography variant="body1" color="white" fontStyle="italic" fontSize={14}>
                confirmation du souhait, transmission et signature des documents, règlements...
              </Typography>
            </Box>
          </Box>
          <Typography variant="body1" color="white" fontWeight={700} fontSize={17}>
            Vous avez déjà adopté ? <br />
            En partenariat avec l’association, un espace vous est dédié pour :
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
            <Typography variant="body1" color="white" fontSize={38}>
              📑
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" color="white" fontWeight={700} fontSize={17}>
                Ses documents
              </Typography>
              <Typography variant="body1" color="white" fontStyle="italic" fontSize={14}>
                liés à l’adoption et à sa santé
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
            <Typography variant="body1" color="white" fontSize={38}>
              🏥
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" color="white" fontWeight={700} fontSize={17}>
                Ses rappels de santé
              </Typography>
              <Typography variant="body1" color="white" fontStyle="italic" fontSize={14}>
                vaccinations, vermifuges, antiparasitaires
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
            <Typography variant="body1" color="white" fontSize={38}>
              🎁
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" color="white" fontWeight={700} fontSize={17}>
                De conseils & recommandations
              </Typography>
              <Typography variant="body1" color="white" fontStyle="italic" fontSize={14}>
                Et des cadeaux de bienvenue !
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
            <Typography variant="body1" color="white" fontSize={38}>
              🧑‍⚕️
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" color="white" fontWeight={700} fontSize={17}>
                L&apos;accès à des experts
              </Typography>
              <Typography variant="body1" color="white" fontStyle="italic" fontSize={14}>
                Réservations, téléconsultations, messagerie...
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 3, width: '100%' }}>
            <Typography variant="body1" color="white" fontSize={38}>
              😻️
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" color="white" fontWeight={700} fontSize={17}>
                Compléter son profil en continu
              </Typography>
              <Typography variant="body1" color="white" fontStyle="italic" fontSize={14}>
                Et ajouter vos autres animaux !
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
