import { Box, Typography } from '@mui/material'
import React from 'react'
import { theme } from '../../../theme'
import { LoadingBar } from '../LoadingBar'
import { AnimalDtoSpecies } from '../../../../interactors/gen/backendClient'

interface StepsProps {
  step: number
  species: AnimalDtoSpecies
}

export const Five: React.FC<StepsProps> = ({ step, species }) => {
  if (species === 'dog') {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: 2, height: '100%' }}>
        <LoadingBar step={step} />
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            fontSize: '22px',
            fontWeight: 700,
            width: 'fit-content',
            p: 1,
            py: 0.5,
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
          }}
        >
          Transport & Absences
        </Box>
        <Typography variant="body1" sx={{ color: theme.palette.secondary.main, fontWeight: 'bold', fontSize: '18px' }}>
          L’abandon est à bannir.
        </Typography>
        <ul style={{ paddingLeft: 20 }}>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            Les <b>petits chiens</b> voyagent dans un sac, les <b>plus grands</b> en laisse et muselés.
          </Typography>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            <b>Évitez les trajets en véhicule non climatisé</b> en été pour prévenir les coups de chaleur.
          </Typography>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            Pour voyager hors de France, <b>la vaccination contre la rage est obligatoire,</b> ainsi qu&apos;une puce
            électronique et un passeport européen (~20 €) dans l’UE.
          </Typography>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            En cas d’absence, <b>prévoyez des frais de garde ou confiez votre chien</b> à une personne de confiance.
          </Typography>
        </ul>
      </Box>
    )
  } else {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: 2, height: '100%' }}>
        <LoadingBar step={step} />
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            fontSize: '22px',
            fontWeight: 700,
            width: 'fit-content',
            p: 1,
            py: 0.5,
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
          }}
        >
          Transport & Absences
        </Box>
        <Typography variant="body1" sx={{ color: theme.palette.secondary.main, fontWeight: 'bold', fontSize: '18px' }}>
          N’abandonnez jamais votre animal.
        </Typography>
        <ul style={{ paddingLeft: 0, listStyleType: 'none' }}>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            🚙 <b>Évitez les trajets en véhicule non climatisé</b> en été : les coups de chaleur peuvent être fatals.
            Dans les transports en commun, utilisez un sac adapté.
          </Typography>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            ✈️ Pour voyager à l’étranger, <b>la vaccination contre la rage est obligatoire,</b> et dans l&apos;UE, le
            chat doit avoir une puce électronique et un passeport européen (~20 €).
          </Typography>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            🏠 Les chats préfèrent souvent <b>rester chez eux en cas d&apos;absence.</b> Pensez à prévoir des frais de
            garde si nécessaire.
          </Typography>
        </ul>
      </Box>
    )
  }
}
