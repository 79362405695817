import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Avatar, Box, Card, CardContent, IconButton, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'
import { getEmojiAnimalSex } from '../../domain/Animal/AnimalDisplay'
import { calculateAge } from '../../domain/Animal/calculateAge'
import { AnimalDto } from '../../interactors/gen/backendClient'
import { getAnimalProfilePhotoSrc } from '../../utils/S3-links'
import { findGoodAnimalProfileURL } from '../../utils/findGoodAnimalProfilURL'
import { ReactComponent as CrossIcon } from '../icons/CrossIcon.svg'
import { metricalpEvent } from '@metricalp/react'

interface Props {
  animal: AnimalDto
  assoEmail: string
  animalName: string
  adopterId: string
  adopterName: string
  alreadyAdopted: boolean
}

export const SmallAnimalCard: React.FC<Props> = ({
  animal,
  assoEmail,
  animalName,
  adopterId,
  adopterName,
  alreadyAdopted,
}) => {
  const [open, setOpen] = useState(false)
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const profileImageKey = animal.images?.profileImageKey

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const pictures = [profileImageKey, ...(animal.images?.otherImagesKeys || [])].filter(Boolean)

  const nextImage = () => {
    console.log(pictures.length)
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % pictures.length)
  }

  const previousImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex - 1 + pictures.length) % pictures.length)
  }

  const onClickEmailEvent = () => {
    metricalpEvent({
      type: `${alreadyAdopted ? 'adopted' : 'adoption'}_profil_contact_asso`,
      adopterId: adopterId,
      adopterName: adopterName,
      animalName: animal?.name,
      animalId: animal?.id,
    })
  }

  const mailBody = `Bonjour,

J’ai adopté ${animal.name} cette année auprès de l’association. J’ai pu accéder à son profil via mon espace adoptant Petso.

En revanche, quelques informations sont manquantes pour assurer son suivi de santé.

Pourriez vous compléter certaines informations sur votre espace Petso pour que je puisse y avoir accès ?

**A SUPPRIMER - Sélectionnez et supprimez les informations en fonction :** 

- Son statut vaccinal
- La date du prochaine vaccin
- La date du prochain du prochain vermifuge
- La date du prochain du prochain antiparasitaire
- Ses antécédents de santé
- Le certificat de bonne santé de ${animal.name}

**A SUPPRIMER - N’oubliez pas de donner quelques nouvelles de ${animal.name} !**

Merci par avance,

Signature`

  const mailtoLink = `mailto:${assoEmail}?subject=Recherche%20d’informations%20suite%20à%20l’adoption%20de%20${encodeURIComponent(
    animal.name
  )}&body=${encodeURIComponent(mailBody)}`

  return (
    <Card sx={{ p: { xs: 1, md: 2 } }}>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: { md: 'column', xs: 'row' }, gap: '24px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Box
              sx={{
                position: 'relative',
                height: { md: '330px', xs: '100px' },
                maxWidth: '100%',
                width: { xs: '100px', md: '300px' },
              }}
            >
              {profileImageKey ? (
                <img
                  style={{
                    width: '100%',
                    maxWidth: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '11.25px',
                    cursor: 'pointer',
                  }}
                  key={profileImageKey}
                  src={profileImageKey ? findGoodAnimalProfileURL(profileImageKey) : ''}
                  onClick={() => handleImageClick(0)}
                />
              ) : (
                <Avatar
                  alt={animalName}
                  sx={{
                    width: '300px',
                    maxWidth: '100%',
                    height: '330px',
                    objectFit: 'cover',
                    borderRadius: '11.25px',
                    cursor: 'pointer',
                  }}
                />
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Typography variant="h1" component="p" sx={{ fontSize: { md: '22px', xs: '20px' } }}>
                    {animalName}
                  </Typography>
                </Box>
                <Typography
                  variant="h1"
                  component="p"
                  color={animal.sex === 'female' ? 'pink' : 'blue'}
                  sx={{ fontSize: { md: '22px', xs: '20px' } }}
                >
                  {animal.sex ? getEmojiAnimalSex(animal.sex) : ''}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Typography
                    variant="body2"
                    component="p"
                    fontSize={{ md: 18, xs: 16 }}
                    fontWeight={500}
                    fontStyle="italic"
                    lineHeight={1.6}
                  >
                    {animal?.birthday?.date ? calculateAge(animal.birthday.date) : ''}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body2" color="black">
                  Des informations manquantes ?
                </Typography>
                <a
                  href={mailtoLink}
                  onClick={() => onClickEmailEvent()}
                  target="_blank"
                  style={{ color: 'black', fontSize: 13 }}
                  rel="noreferrer"
                >
                  Contactez l&apos;association !
                </a>
              </Box>
            </Box>
          </Box>
        </Box>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'background.paper',
              borderRadius: '8px',
              p: '32px',
              gap: '24px',
              border: 'none',
              position: 'relative',
            }}
          >
            <IconButton onClick={handleClose} sx={{ position: 'absolute', top: '0px', right: '0px' }}>
              <CrossIcon />
            </IconButton>
            <IconButton
              onClick={previousImage}
              sx={{
                position: 'absolute',
                left: '0px',
                top: '50%',
                transform: 'translateY(-50%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ArrowForwardIosIcon sx={{ transform: 'rotate(180deg)' }} />
            </IconButton>
            <IconButton
              onClick={nextImage}
              sx={{ position: 'absolute', right: '0px', top: '50%', transform: 'translateY(-50%)' }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
            <img
              style={{ maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain' }}
              src={getAnimalProfilePhotoSrc(pictures[selectedImageIndex] || '')}
            />
          </Box>
        </Modal>
      </CardContent>
    </Card>
  )
}
