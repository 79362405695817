import { AdopterDto } from '../interactors/gen/backendClient'
import { PATHS } from '../views/PATHS'

export const findHome = (member: AdopterDto | null) => {
  if (member?.adoptionAttempts && member.adoptionAttempts.length > 0) {
    const attempt =
      member.adoptionAttempts.find(
        (adoption) =>
          adoption.done &&
          (adoption.preCertificateDialogStep == null || adoption.preCertificateDialogStep < 1) &&
          !['suspended-by-customer', 'suspended-by-user'].includes(adoption.status)
      ) || null
    if (attempt) {
      return PATHS.adoptedTutorial.absolute
    }
  }
  if (member?.customerId !== 'C-999' && (!member?.tutorialStep || member.tutorialStep < 3)) {
    return PATHS.tutorial.absolute
  }
  if (member?.adoptionAttempts && member.adoptionAttempts.length > 0) {
    if (member.adoptionAttempts.some((adoption) => adoption.status === 'done' && adoption.step >= 6)) {
      return PATHS.animaux.absolute
    } else {
      return PATHS.adoptions.absolute
    }
  }
  return PATHS.monProfil.absolute
}
