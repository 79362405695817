import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { colors, theme } from '../../../theme'
import { LoadingBar } from '../LoadingBar'

interface StepsProps {
  step: number
  subStep: number
  animalName: string
  handleNextStep: () => Promise<void>
}

/* interface FormParams {
  animalName: string
  species: string
  sex: string
  birthDate: string
  breedType: string
  breed: string
  email: string
  phoneNumber: string
  postalCode: string
} */

export const Four: React.FC<StepsProps> = ({ step, handleNextStep, subStep, animalName }) => {
  const Step1 = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: 2, height: '100%' }}>
        <LoadingBar step={step} />
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            fontSize: '22px',
            fontWeight: 700,
            width: 'fit-content',
            p: 1,
            py: 0.5,
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
          }}
        >
          Imprévus & assurances
        </Box>
        <Typography variant="body1" sx={{ fontWeight: 700, color: theme.palette.secondary.main, fontSize: '18px' }}>
          Face aux imprévus, protégez-vous avec une assurance.
        </Typography>
        <Typography variant="body1" sx={{ color: 'black' }}>
          💡 <b>Les accidents et problèmes de santé</b> peuvent coûter cher. Pour faire face aux imprévus,
          <span style={{ color: theme.palette.secondary.main, fontWeight: 700 }}>
            {' '}
            les assurances pour animaux
          </span>{' '}
          sont de plus en plus est recommandées.
        </Typography>
        <Typography variant="body1" sx={{ color: 'black', fontWeight: 700 }}>
          🧘 Demandez au moins un devis personnalisé gratuit pour {animalName} !
        </Typography>
        <Button
          variant="outlined"
          onClick={() => handleNextStep()}
          sx={{
            borderColor: '#c5d2de',
            color: '#6b7990',
            textTransform: 'none',
            my: 1,
            width: '100%',
            fontSize: '18px',
            ':hover': {
              backgroundColor: '#eef2f7',
            },
          }}
        >
          Je ne souhaite pas de devis
        </Button>
        <Typography variant="body1" sx={{ color: 'black' }}>
          <span style={{ fontWeight: 700, color: theme.palette.secondary.main }}>Notre recommandation : Kozoo !</span>
          <ul style={{ listStyle: 'none', paddingLeft: 0, fontSize: '16px' }}>
            <li>👉 1 mois offert avec le code promo Petso1</li>
            <li>👉 Dès 5,15 €/mois</li>
            <li>👉 Offres sur-mesure, dès 2 mois et à vie</li>
          </ul>
        </Typography>
      </Box>
    )
  }

  /* const Step2 = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2, height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                fontSize: '20px',
                fontWeight: 700,
                width: 'fit-content',
                p: 1,
                py: 0.5,
                borderRadius: '11px',
                transform: 'rotate(-1deg)',
              }}
            >
              Devis gratuit !
            </Box>
            <Typography variant="body1" sx={{ color: 'black' }}>
              Calculez le tarif personnalisé d’une assurance pour {animalName}
            </Typography>
          </Box>
          <img src="/images/kozoo.png" alt="Kozoo" />
        </Box>
      </Box>
    )
  } */

  const Step3 = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 3.5,
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <span style={{ fontSize: '72px' }}>🎉</span>
          <Typography variant="h5" sx={{ color: 'black', fontWeight: 700 }}>
            Demande envoyée
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ color: 'black', textAlign: 'center' }}>
          Vous recevrez par mail, dans les 24h le prix de l’assurance adapté pour {animalName}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 2 }}>
          <Box
            sx={{
              backgroundColor: colors.blueSky,
              color: 'white',
              fontSize: '20px',
              fontWeight: 700,
              width: 'fit-content',
              p: 1,
              py: 0.5,
              borderRadius: '11px',
              transform: 'rotate(-1deg)',
            }}
          >
            Bon plan Petso !
          </Box>
          <Typography variant="body1" sx={{ color: 'black', textAlign: 'center' }}>
            Vous pouvez bénéficier d&apos;1 mois offert avec le code <b>Petso1</b>
          </Typography>
        </Box>
      </Box>
    )
  }
  switch (subStep) {
    case 1:
      return <Step1 />
    case 2:
      return <Step3 />
    default:
      return null
  }
}
