import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AdoptionAddNewItem } from './AdoptionAddNewItem'
import { AdoptionItem } from './AdoptionItem'
import { AdopterDto } from '../../interactors/gen/backendClient'
import { AuthClient } from '../../interactors/clients/AuthClient'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { useAccountsStore } from '../../store/AccountsStore'

export const AdoptionsListScreen: React.FC = () => {
  const [adopter, setAdopter] = useState<AdopterDto | null>(null)
  const queryParams = new URLSearchParams(window.location.search)
  const fromTutorial = queryParams.get('fromTutorial') === 'true'

  const accountsStore = useAccountsStore()

  useEffect(() => {
    const fetchAdopter = async () => {
      const adopter = await AuthClient.getCurrentAccount()
      setAdopter(adopter)
      accountsStore.changeConnectedAccount(adopter)
    }

    fetchAdopter()
  }, [])

  if (adopter === null) {
    return <CircularProgressPanel />
  }

  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', pb: 20 }}>
      <Box sx={{ maxWidth: '1200px', marginX: 'auto', marginY: 1, p: 4, pb: 12 }}>
        <Typography variant="h1">{fromTutorial ? `Sélectionnez un animal` : `Mes demandes d'adoptions`}</Typography>
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {adopter?.adoptionAttempts ? (
            adopter?.adoptionAttempts.map((adoption) => <AdoptionItem key={adoption.id} adoption={adoption} />)
          ) : (
            <AdoptionAddNewItem />
          )}
        </Box>
      </Box>
    </Box>
  )
}
