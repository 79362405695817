import { Box, Typography } from '@mui/material'
import React from 'react'
import { theme } from '../../../theme'
import { LoadingBar } from '../LoadingBar'
import { AnimalDtoSpecies } from '../../../../interactors/gen/backendClient'

interface StepsProps {
  step: number
  species: AnimalDtoSpecies
}

export const One: React.FC<StepsProps> = ({ step, species }) => {
  if (species === 'dog') {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: 2, height: '100%' }}>
        <LoadingBar step={step} />
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            fontSize: '22px',
            fontWeight: 700,
            width: 'fit-content',
            p: 1,
            py: 0.5,
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
          }}
        >
          Vie & environnement
        </Box>
        <Typography
          variant="body1"
          sx={{ color: theme.palette.secondary.main, fontWeight: 'bold', fontSize: '18px', mt: 2 }}
        >
          Adopter un chien est un engagement de 8 à 15 ans.
        </Typography>
        <ul style={{ paddingLeft: 20 }}>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            Les chiens dorment entre 12 et 15 heures par jour (jusqu’à 20h pour les chiots).{' '}
            <span style={{ fontWeight: 700 }}>Offrez-leur un espace calme pour se reposer.</span>
          </Typography>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            Les sorties sont essentielles : <span style={{ fontWeight: 700 }}>au moins 3 fois par jour</span>, surtout
            après le réveil et les repas, pour leur bien-être et leur propreté.
          </Typography>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            Les chiens ont besoin de{' '}
            <span style={{ fontWeight: 700 }}>stimulation mentale et d&apos;occupations comme la mastication.</span>{' '}
            Laissez-les exprimer leurs comportements naturels (creuser, aboyer, renifler), indispensables à leur
            équilibre.
          </Typography>
        </ul>
      </Box>
    )
  } else {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: 2, height: '100%' }}>
        <LoadingBar step={step} />
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            fontSize: '22px',
            fontWeight: 700,
            width: 'fit-content',
            p: 1,
            py: 0.5,
            borderRadius: '11px',
            transform: 'rotate(-1deg)',
          }}
        >
          Vie & environnement
        </Box>
        <Typography
          variant="body1"
          sx={{ color: theme.palette.secondary.main, fontWeight: 'bold', mt: 2, fontSize: '18px' }}
        >
          Adopter un chat est un engagement de 15 à 20 ans.
        </Typography>
        <ul style={{ paddingLeft: 0, listStyleType: 'none' }}>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            💤 <b>Les chats dorment beaucoup</b> (jusqu’à 18h par jour). Offrez-leur des coins calmes et douillets
          </Typography>
          <Typography component="li" variant="body1" sx={{ color: 'black', mb: 2 }}>
            🛀 <b>Assurez une litière propre</b> et adaptée, à nettoyer chaque jour.
          </Typography>
          <Typography component="li" variant="body1" sx={{ color: 'black' }}>
            🧸 Enrichissez son espace avec des jouets, perchoirs et cachettes pour stimuler son instinct naturel,{' '}
            <b>surtout s’il reste à l’intérieur.</b>
          </Typography>
        </ul>
      </Box>
    )
  }
}
