import { metricalpEvent } from '@metricalp/react'
import { LoadingButton } from '@mui/lab'
import { Box, CircularProgress } from '@mui/material'
import React, { useEffect } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { AdoptersClient } from '../../../interactors/clients/AdoptersClient'
import { AdoptionAttemptDto, AnimalDto } from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { colors } from '../../theme'
import { Five } from './Steps/Five'
import { Four } from './Steps/Four'
import { One } from './Steps/One'
import { Three } from './Steps/Three'
import { Two } from './Steps/Two'
import { Zero } from './Steps/Zero'

interface Props {
  animal: AnimalDto
  attemptId: string
  setAdoptionAttempt: (attempt: AdoptionAttemptDto) => void
  animalName: string
  initialStep?: number
  alreadyAdopted?: boolean
  certificateHandDelivered?: boolean
}

export const CertificateTutorial: React.FC<Props> = ({
  animal,
  attemptId,
  setAdoptionAttempt,
  initialStep,
  animalName,
  alreadyAdopted,
  certificateHandDelivered = false,
}) => {
  const [step, setStep] = React.useState(initialStep || 0)

  const navigate = useNavigate()
  const accountsStore = useAccountsStore()

  // Used only for step 4 to manage sub steps
  const [subStep, setSubStep] = React.useState(1)

  const setStepMutation = useMutation(
    (step: number) => AdoptersClient.updateAdoptionAttemptTutorialStep(step, attemptId),
    {
      onSuccess: (attempt) => {
        setAdoptionAttempt(attempt)
        const index = accountsStore.connectedAccount?.adoptionAttempts?.findIndex((attempt) => attempt.id === attemptId)
        if (index && accountsStore.connectedAccount && accountsStore.connectedAccount.adoptionAttempts) {
          accountsStore.connectedAccount.adoptionAttempts[index] = attempt
        }
      },
    }
  )

  useEffect(() => {
    const endTutorial = async () => {
      if (initialStep && initialStep === 9) {
        await setStepMutation.mutateAsync(9)
        return navigate(`/adoptions/${attemptId}?congrats=gifts`)
      }
    }
    endTutorial()
  }, [])

  const sendInsuranceEstimateMutation = useMutation(
    (animalId: string) => AdoptersClient.sendInsuranceEstimate(animalId),
    {
      onSuccess: () => {
        setSubStep(2)
      },
    }
  )

  const handleNextStep = async () => {
    // As we now skip the tutorial we will always set step 9
    await setStepMutation.mutateAsync(9)
    const chatContainer = document.querySelector('.scrollable-content') as HTMLElement
    chatContainer.scrollTop = 0
    metricalpEvent({
      type: `${alreadyAdopted ? 'adopted' : 'adoption'}_tutorial_certificate`,
      adopterId: accountsStore.connectedAccount?.id,
      adopterName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
      validatedStep: `${step}`,
      done: step === 5,
      skipDevis: step === 4 && subStep < 2,
    })
    if (step === 5) {
      if (animal.adoption.status === 'has-been-adopted') {
        return navigate(`/adoptions/${attemptId}?congrats=gifts`)
      }
      navigate(`/adoptions/${attemptId}?congrats=tutorial`)
    }
    // As we now skip the tutorial we will always set step 9
    setStep(9)
    navigate(`/adoptions/${attemptId}?page=action`)
  }

  const handleNextSubStep = async () => {
    metricalpEvent({
      type: `${alreadyAdopted ? 'adopted' : 'adoption'}_tutorial_certificate_devis`,
      adopterId: accountsStore.connectedAccount?.id,
      adopterName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
      animalName: animal.name,
      animalId: animal.id,
    })
    await sendInsuranceEstimateMutation.mutateAsync(animal.id)
  }

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <Zero animal={animal} animalName={animalName || ''} certificateHandDelivered={certificateHandDelivered} />
        )
      case 1:
        return <One step={step} species={animal.species} />
      case 2:
        return <Two step={step} species={animal.species} />
      case 3:
        return <Three step={step} species={animal.species} />
      case 4:
        return <Four step={step} subStep={subStep} animalName={animalName || ''} handleNextStep={handleNextStep} />
      case 5:
        return <Five step={step} species={animal.species} />
      default:
        return null
    }
  }

  const backgroundColor = step === 0 ? colors.blueSky : 'white'

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: { xs: 0, md: 0 },
        height: '100%',
        pb: 2,
        backgroundColor: backgroundColor,
      }}
    >
      <Box
        sx={{
          maxWidth: '1200px',
          marginX: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          pt: 1,
          pb: 4,
          px: 3,
          gap: 2,
          width: '100%',
          flexGrow: 1,
        }}
      >
        {setStepMutation.isLoading || sendInsuranceEstimateMutation.isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : (
          renderStep()
        )}
        {/* <div style={{ height: '69px', backgroundColor: backgroundColor }}></div> */}
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: { xs: 0, md: '240px' },
            right: 0,
            p: 2,
            px: 3,
            pt: 1,
            pb: 4,
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            alignItems: 'center',
            borderTop: step === 0 ? 'none' : '1px solid #E0E0E0',
            boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.10)',
            zIndex: 2,
            backgroundColor: backgroundColor,
          }}
        >
          <LoadingButton
            variant="contained"
            onClick={() => (step !== 4 ? handleNextStep() : subStep !== 2 ? handleNextSubStep() : handleNextStep())}
            sx={{ fontSize: '18px', fontWeight: 700 }}
            fullWidth
            loading={setStepMutation.isLoading || sendInsuranceEstimateMutation.isLoading}
            disabled={setStepMutation.isLoading || sendInsuranceEstimateMutation.isLoading}
          >
            {step === 0
              ? certificateHandDelivered
                ? 'Transmettre mon certificat signé'
                : 'Signer le certificat'
              : step !== 4
              ? `J'ai compris`
              : subStep !== 2
              ? `Je demande`
              : `C'est noté !`}
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  )
}
