import { LoadingButton } from '@mui/lab'
import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import { useMutation } from 'react-query'
import { Navigate } from 'react-router-dom'
import { AdoptersClient } from '../../interactors/clients/AdoptersClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { colors } from '../theme'
import { One } from './Steps/One'
import { Two } from './Steps/Two'
import { metricalpEvent } from '@metricalp/react'

export const Tutorial: React.FC = () => {
  const accountsStore = useAccountsStore()
  const connectedAccount = accountsStore.connectedAccount
  const hasSeveralAdoptions = connectedAccount?.adoptionAttempts && connectedAccount?.adoptionAttempts?.length > 1
  const attemptId =
    connectedAccount?.adoptionAttempts && !hasSeveralAdoptions ? connectedAccount?.adoptionAttempts[0].id : ''

  const [step, setStep] = React.useState(connectedAccount?.tutorialStep || 1)

  const setStepMutation = useMutation((step: number) => AdoptersClient.updateTutorialStep(step), {
    onSuccess: (account) => {
      accountsStore.changeConnectedAccount(account)
    },
  })

  const handleNextStep = async () => {
    if (step === 2) {
      metricalpEvent({
        type: `tutorial_petso`,
        adopterId: accountsStore.connectedAccount?.id,
        adopterName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
        done: true,
      })
    }
    await setStepMutation.mutateAsync(step + 1)
    setStep(step + 1)
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return <One />
      case 2:
        return <Two />
      case 3:
        return (
          <Navigate
            to={`/profil/mon-profil?fromTutorial=true&redirect=/adoptions${
              attemptId ? `/${attemptId}` : ''
            }?fromTutorial=true`}
          />
        )
      default:
        return null
    }
  }
  return (
    <Box
      sx={{
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        padding: { xs: 0, md: 0 },
        height: '100%',
        width: '100%',
        pb: 2,
        bgcolor: colors.blueSky,
      }}
    >
      <Box
        sx={{
          maxWidth: '1200px',
          marginX: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          py: 4,
          px: 3,
          gap: 2,
          width: '100%',
          flexGrow: 1,
        }}
      >
        {setStepMutation.isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : (
          renderStep()
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', pb: 2, gap: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: { xs: '100%', md: 'calc(100% - 240px)' },
              justifyContent: 'end',
              mb: { xs: 0, md: 2 },
            }}
          >
            <LoadingButton
              variant="contained"
              onClick={() => handleNextStep()}
              sx={{ fontSize: '18px' }}
              loading={setStepMutation.isLoading}
            >
              {step < 2 ? `J'ai compris` : `Commencer`}
            </LoadingButton>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', position: 'relative' }}>
            <ul className="slick-dots slick-dots--tutorial" style={{ bottom: '0px !important' }}>
              <li className={step === 1 ? 'slick-active' : ''}>
                <div className="ft-slick__dots--tutorial"></div>
              </li>
              <li className={step === 2 ? 'slick-active' : ''}>
                <div className="ft-slick__dots--tutorial"></div>
              </li>
            </ul>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
